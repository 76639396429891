import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./index";

const initialState = {};
const middleware = [thunk];
export default createStore(
    rootReducer,
    initialState,
    compose(
        applyMiddleware(...middleware)
        //(window as any)['devToolsExtension'] && (window as any)['devToolsExtension']()
    )
);
