import * as React from 'react';
import pp_logo from "./poolparty_logo.svg";
import {NavLink} from "react-router-dom";

interface NavbarProps {
}

export default class Navbar extends React.Component<NavbarProps> {

    render () {
        return (
            <div className="main-header">
                <div className="header-left">
                    <a href="https://poolparty.biz">
                        <img className="poolparty-logo" src={pp_logo} alt={'PoolParty'} />
                    </a>
                    <div>
                        <NavLink
                            to="/"
                            className="home-link"
                            activeClassName='active'
                        >
                            Contract Intelligence Demo
                        </NavLink>
                    </div>
                </div>
                <div className="header-right">
                    <nav className="navbar-expand-lg">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink
                                    to="/about"
                                    className="nav-link"
                                    activeClassName='active'
                                >
                                    About
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        );
    }
}
