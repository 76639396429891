import {
    ContractsState,
    ContractsActions,
    UPDATE_SENSES,
    SHOW_CONTRACTS,
    SHOW_CONTRACT_DETAILS,
    GET_CONCEPTS
} from "./types";
import {Contract, Document} from "./api";

const initialState: ContractsState = {
    senses: [],
    contracts: [],
    contractDetails: {document: {paragraphs:[], text:'', title:'', uri:''} as Document, senses: []} as Contract,
    concepts: {}
};

export function contractsReducer(state:ContractsState = initialState, action:ContractsActions): ContractsState {
    switch (action.type) {
        case UPDATE_SENSES:
            return {
                ...state,
                senses: action.payload
            };
        case SHOW_CONTRACTS:
            return {
                ...state,
                contracts: action.payload
            };
        case SHOW_CONTRACT_DETAILS:
            return {
                ...state,
                contractDetails: action.payload
            };
        case GET_CONCEPTS:
            return {
                ...state,
                concepts: action.payload
            };

        default:
            return state;
    }
}
