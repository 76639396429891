import * as React from 'react';
import {
    Form,
    Overlay,
    Tab,
    Tabs,
    Tooltip
} from "react-bootstrap";
import {RouteComponentProps} from "react-router";
import {connect} from "react-redux";
import {AppState} from "../../store";
import {
    getSenses,
    getContracts,
    getContractDetails,
    getConcepts
} from "../../store/contracts/actions";
import {ContractsState} from "../../store/contracts/types";
import {
    ConceptMatching,
    ContractsItem,
    Paragraph, Rule,
    Sense,
    Sentence
} from "../../store/contracts/api";
import word_doc_image from "./word-document.svg";
import HtmlParser from "react-html-parser";

interface ContractToolAreaState {
    selectedTab:string;
    tabRules: any;
    selectedContractID:string;
    selectedSentenceIndex: number;
    tabReferences:any;
    senseCheckboxValues:any;
    validDocumentIds: string[];
    loadingContracts: boolean;
    loadingSenses: boolean;
    loadingContractDetails: boolean;
    loadingConcepts: boolean;
    tooltipText: string;
}

interface ContractToolAreaProps extends RouteComponentProps<any> {
    getSenses: () => void,
    getContracts: () => void,
    getContractDetails: (documentId:string) => void,
    getConcepts: () => void,
    contracts: ContractsState,
}

class ContractTool extends React.Component<ContractToolAreaProps, ContractToolAreaState> {
    constructor (props:ContractToolAreaProps) {
        super(props);

        this.state = {
            selectedTab: '',
            selectedContractID: '',
            tabRules: {},
            selectedSentenceIndex: 0,
            tabReferences: {},
            senseCheckboxValues: {},
            validDocumentIds: [],
            loadingContracts: true,
            loadingSenses: true,
            loadingContractDetails: false,
            loadingConcepts: true,
            tooltipText: ''
        };
    }

    componentDidMount() {
        this.props.getSenses();
        this.props.getContracts();
        this.props.getConcepts();
    }

    changeResultTab(key:string) {
        this.setState({selectedTab: key, selectedSentenceIndex: 0});
    }

    /**
     * Show the details of a contract.
     * @param {string } uri
     *   The URI of the contract to display details for.
     */
    toggleContractDetails(uri:string) {
        // Show the contract details.
        if (uri !== this.state.selectedContractID) {
            this.setState({selectedContractID: uri});
            if (this.props.contracts.contractDetails.document.uri !== uri) {
                this.setState({loadingContractDetails: true});
                this.props.getContractDetails(uri);
            }
        }
        // Hide the contract details.
        else {
            this.setState({selectedContractID: ''})
        }
    }

    /**
     * Set the index of the currently active sentence.
     *
     * @param {number} new_sentence_id
     *   The index of the sentence to make active.
     */
    setSentenceId(new_sentence_id:number) {
        this.setState({selectedSentenceIndex: new_sentence_id});
    }

    senseCheckboxChange(sense_id:string, event:any) {
        let newSenseCheckboxValues:any = this.state.senseCheckboxValues;
        newSenseCheckboxValues[sense_id] = event.target.checked;

        let sense_ids: string[] = this.getSelectedSenses();
        this.setState({senseCheckboxValues: newSenseCheckboxValues});
        this.updateValidDocumentList((sense_ids.length !== Object.keys(newSenseCheckboxValues).length) ? sense_ids : []);
    }

    componentDidUpdate(prevProps:ContractToolAreaProps, prevState:ContractToolAreaState) {
        // The opened document changed.
        if (prevProps.contracts.contractDetails.document.uri !== this.props.contracts.contractDetails.document.uri || (this.state.loadingContracts && this.props.contracts.contractDetails.document.uri.length > 0)) {
            // Directly open the correct document on reloading the page.
            if (this.props.contracts.contractDetails.document.uri !== this.state.selectedContractID) {
                this.setState({selectedContractID: this.props.contracts.contractDetails.document.uri});
            }

            let _self = this;
            let newTabRules:any = {};
            if (this.props.contracts.contractDetails.senses.length > 0) {
                this.props.contracts.contractDetails.senses[0].senses.forEach(function(sense) {
                    _self.props.contracts.contractDetails.document.paragraphs.forEach(function(paragraph) {
                        paragraph.sentences.forEach(function(sentence) {
                            let rules = _self.getSenseInformation(sentence.index, sense.uri);
                            if (rules.length > 0) {
                                // Add the rules to the tabs.
                                if (!newTabRules.hasOwnProperty(sense.uri)) {
                                    newTabRules[sense.uri] = {};
                                    _self.state.tabReferences[sense.uri] = [];
                                }
                                newTabRules[sense.uri][sentence.index] = rules;

                                // Add the tab references.
                                _self.state.tabReferences[sense.uri].push(React.createRef());
                            }
                        })
                    });
                });
            }
            this.setState({tabRules: newTabRules, loadingContractDetails: false, selectedSentenceIndex: 0});

            // There is no tab selected and there is one available --> set a default tab.
            if ((this.state.selectedTab === '' || Object.keys(newTabRules).indexOf(this.state.selectedTab) === -1) && this.props.contracts.contractDetails.senses.length > 0) {
                let new_selected_tab = '';
                let selected_senses = this.getSelectedSenses();
                for (let sense_count = 0; sense_count < this.props.contracts.contractDetails.senses[0].senses.length; sense_count++) {
                    if (selected_senses.indexOf(this.props.contracts.contractDetails.senses[0].senses[sense_count].uri) > -1 || selected_senses.length === 0) {
                        new_selected_tab = this.props.contracts.contractDetails.senses[0].senses[sense_count].uri;
                        break;
                    }
                }
                this.changeResultTab(new_selected_tab);
            }
        }

        // The sense checkbox options changed.
        if (prevProps.contracts.senses.length !== this.props.contracts.senses.length || (this.state.loadingSenses && this.props.contracts.senses.length > 0)) {
            let newSenseCheckboxValues:any = {};
            for (let sense_index = 0; sense_index < this.props.contracts.senses.length; sense_index++) {
                newSenseCheckboxValues[this.props.contracts.senses[sense_index].uri] = false;
            }
            this.setState({senseCheckboxValues: newSenseCheckboxValues, loadingSenses: false});
        }

        // The document list changed.
        if (prevProps.contracts.contracts.length !== this.props.contracts.contracts.length || (this.state.loadingContracts && this.props.contracts.contracts.length > 0)) {
            this.updateValidDocumentList([]);
            this.setState({loadingContracts: false});
        }

        // The concepts list changed.
        if (Object.keys(prevProps.contracts.concepts).length !== Object.keys(this.props.contracts.concepts).length || (this.state.loadingConcepts && Object.keys(this.props.contracts.concepts).length > 0)) {
            this.setState({loadingConcepts: false});
        }

        // Scroll to the correct sentence.
        if (prevState.selectedTab !== this.state.selectedTab || prevState.selectedSentenceIndex !== this.state.selectedSentenceIndex || (prevState.loadingContractDetails && !this.state.loadingContractDetails)) {
            if (this.state.tabReferences.hasOwnProperty(this.state.selectedTab) && this.state.tabReferences[this.state.selectedTab].hasOwnProperty(this.state.selectedSentenceIndex) && this.state.tabReferences[this.state.selectedTab][this.state.selectedSentenceIndex].current) {
                let sentence_element = this.state.tabReferences[this.state.selectedTab][this.state.selectedSentenceIndex].current;
                //sentence_element.parentNode.scrollTop = sentence_element.offsetTop;
                document.getElementsByClassName('contract-details')[0].scrollTop = sentence_element.offsetTop;
                document.getElementsByClassName('document-row-active')[0].scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                });
            }

            // Update the tooltip text.
            let _self = this;
            let sentence_id = Object.keys(this.state.tabRules[this.state.selectedTab])[this.state.selectedSentenceIndex];
            let tooltip_html = '<p class="tooltip-applied-rules">Applied rules</p>';
            this.state.tabRules[this.state.selectedTab][sentence_id].forEach(function(rule:Rule) {
                if (rule.conceptMatchings) {
                    tooltip_html += '<div class="tooltip-rule">Rule <span class="tooltip-rule-title">' + rule.title + '</span> was applied because ';
                    let concept_strings:string[] = [];
                    rule.conceptMatchings.forEach(function (concept) {
                        if (_self.props.contracts.concepts.hasOwnProperty(concept.uri)) {
                            concept_strings.push(_self.props.contracts.concepts[concept.uri].prefLabel);
                        }
                    });

                    // Make the list of concepts unique.
                    concept_strings = concept_strings.filter((v,i,a)=>a.indexOf(v)===i);

                    // Multiple concepts were found in the same context.
                    if (concept_strings.length > 1) {
                        tooltip_html += 'concepts <span class="tooltip-rule-concept">' + concept_strings.slice(0, -1).join('</span>, <span class="tooltip-rule-concept">') + '</span> and <span class="tooltip-rule-concept">' + concept_strings.slice(-1) + '</span> were found in the same context.</div>';
                    }
                    // A single concept was used for this rule.
                    else {
                        tooltip_html += 'concept <span class="tooltip-rule-concept">' + concept_strings[0] + '</span> was found.</div>';
                    }
                }
            });
            this.setState({tooltipText: tooltip_html});
        }
    }

    /**
     * Update the list of documents to display depending on the selected senses
     * @param senseIds
     *   The IDs of the senses, which have to be applicable
     */
    updateValidDocumentList(senseIds:string[]) {
        let newContractIDs:string[] = [];
        this.props.contracts.contracts.forEach(function(current_contract) {
            let valid = true;
            for (let sense_index = 0; sense_index < senseIds.length; sense_index++) {
                let found = false;

                current_contract.senseItems.forEach(function (senseItem) {
                    if (senseItem.uri === senseIds[sense_index]) {
                        found = true;
                    }
                });

                if (!found) {
                    valid = false;
                    break;
                }
            }

            if (valid) {
                newContractIDs.push(current_contract.contract);
            }
        });

        this.setState({validDocumentIds: newContractIDs});

        // Unset or update the selected contract if required.
        if (this.state.selectedContractID.length > 0) {
            // Unset the document.
            if (newContractIDs.indexOf(this.state.selectedContractID) === -1) {
                this.setState({selectedContractID: ''});
            }
            // Update the tab.
            else if (senseIds.indexOf(this.state.selectedTab) === -1) {
                for (let sense_count = 0; sense_count < this.props.contracts.contractDetails.senses[0].senses.length; sense_count++) {
                    if (senseIds.indexOf(this.props.contracts.contractDetails.senses[0].senses[sense_count].uri) > -1) {
                        this.changeResultTab(this.props.contracts.contractDetails.senses[0].senses[sense_count].uri);
                        break;
                    }
                }
            }
        }
    }

    getSelectedSenses() {
        let checked_sense_ids:string[] = [];
        for (let sense_id in this.state.senseCheckboxValues) {
            if (this.state.senseCheckboxValues[sense_id]) {
                checked_sense_ids.push(sense_id);
            }
        }

        if (checked_sense_ids.length === 0) {
            checked_sense_ids = Object.keys(this.state.senseCheckboxValues);
        }

        return checked_sense_ids;
    }

    getSenseInformation(sentence_index:number, sense_id:string): Rule[] {
        let rules: Rule[] = [];
        if (this.props.contracts.contractDetails.senses.length > 0) {
            this.props.contracts.contractDetails.senses[0].senses.forEach(function(senseItem) {
                if (senseItem.uri === sense_id) {
                    for (let area_id = 0; area_id < senseItem.areas.length; area_id++) {
                        for (let sentence_id = 0; sentence_id < senseItem.areas[area_id].sentences.length; sentence_id++) {
                            // Thi sentence is relevant for the sense.
                            if (senseItem.areas[area_id].sentences[sentence_id]['index'] === sentence_index) {
                                rules = senseItem.areas[area_id].rules || [];
                                // Get out of the forEach loop.
                                return;
                            }
                        }
                    }
                }
            });
        }
        return rules;
    }

    isLoading() {
        return (this.state.loadingContracts || this.state.loadingSenses || this.state.loadingContractDetails || this.state.loadingConcepts);
    }

    render () {
        return (
            <div className="results-container">
                {
                    this.isLoading() ? <div className="loading">Loading&#8230;</div> : ''
                }
                <div className="row startpage-intro">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <article className="article">
                            <div className="article-details intro-paragraph">
                                <p>PoolParty Sense Extraction makes contract management much easier. Use the left sidebar to navigate to specific sections of the contracts listed on the right. Extract information from current and legacy contracts to minimize risk, stay compliant, uncover hidden costs and opportunities and make informed business decisions.</p>
                            </div>
                        </article>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                        <article className="article">
                            <div className="article-details">
                                <h4 className="rule-filter-headline">Filter Documents by Rule</h4>
                                <Form className="rule-filter-form">
                                {this.props.contracts.senses.map((result, index:any) => {
                                    return <Form.Check
                                        key={'contract-checkbox-'+index}
                                        custom
                                        type={'checkbox'}
                                        id={'sense-map-' + result.uri}
                                        label={result.title}
                                        onChange={(event:any) => this.senseCheckboxChange(result.uri, event)}
                                    />
                                })}
                                </Form>
                            </div>
                        </article>
                    </div>
                    <div className="col-12 col-sm-8 col-md-8 col-lg-8">
                        <article className="article">
                            <div className="article-details">
                                <h4 className="document-list-headline">Matching Documents</h4>
                                {this.state.validDocumentIds.length > 0 ? this.props.contracts.contracts.map((result:ContractsItem, index:any) => {
                                    let selected_senses = this.getSelectedSenses();
                                    return this.state.validDocumentIds.indexOf(result.contract) > -1 ? (
                                        <div className={'row document-row' + (this.state.selectedContractID === result.contract ? ' document-row-active' : '')} key={result.contract+index}>
                                            <div className="contract-title col-12 col-sm-8 col-md-8 col-lg-8" onClick={() => this.toggleContractDetails(result.contract)}>
                                                <div className="document-icon-container"><img className="document-icon-word" src={word_doc_image} alt={'Document'} /></div>
                                                <div className="contract-title-text">{result.title}</div>
                                            </div>
                                            <div className="contract-operations col-12 col-sm-4 col-md-4 col-lg-4">
                                                { this.state.selectedContractID !== result.contract ?
                                                  <button className="show-contract-details-btn btn btn-primary" onClick={() => this.toggleContractDetails(result.contract)}>show details</button>
                                                    : <button className="show-contract-details-btn btn btn-primary" onClick={() => this.toggleContractDetails(result.contract)}>hide details</button> }
                                                <a href={result.url} className="download-contract-btn btn btn-primary"><i className="fa fa-download" /></a>
                                            </div>
                                            { this.state.selectedContractID === result.contract ?
                                                <div className="contract-details-container">
                                                    <Tabs className="nav nav-pills recommender-result-nav" id="contract-rule-tabs" activeKey={this.state.selectedTab} onSelect={(key:string) => this.changeResultTab(key)}>
                                                        {
                                                            this.props.contracts.contractDetails.senses.length > 0 ? this.props.contracts.contractDetails.senses[0].senses.map((sense:Sense, sense_index:any) => {
                                                            if (selected_senses.indexOf(sense.uri) > -1) {
                                                                let relevant_sentence_count: number = (this.state.tabRules.hasOwnProperty(sense.uri) ? Object.keys(this.state.tabRules[sense.uri]).length : 0);
                                                                let current_relevant_sentence_id = 0;
                                                                let sense_list_item = this.props.contracts.senses.find(check_sense => { return check_sense.uri === sense.uri });

                                                                return <Tab className="nav-link" eventKey={sense.uri} title={sense_list_item ? sense_list_item.title : sense.title} key={sense_index}>
                                                                    {sense.uri === this.state.selectedTab ? (
                                                                        <div>
                                                                            <div className="relevant-sentence-jumper">
                                                                                <div className="prev-sentence-button-container">
                                                                                    {(this.state.selectedSentenceIndex > 0) ?
                                                                                        <button className="prev-sentence-button btn btn-primary fas fa-arrow-left" onClick={() => this.setSentenceId(this.state.selectedSentenceIndex - 1)}/> : ''}
                                                                                </div>
                                                                                Relevant
                                                                                sentence <span className="current-sentence-index">{this.state.selectedSentenceIndex + 1}</span> of <span className="sentences-total">{relevant_sentence_count}</span>
                                                                                <div className="next-sentence-button-container">
                                                                                    {(this.state.selectedSentenceIndex < (relevant_sentence_count - 1)) ?
                                                                                        <button className="next-sentence-button btn btn-primary fas fa-arrow-right" onClick={() => this.setSentenceId(this.state.selectedSentenceIndex + 1)}/> : ''}
                                                                                </div>
                                                                            </div>
                                                                            <div className="contract-details col-12 col-sm-12 col-md-12 col-lg-12">
                                                                                {this.props.contracts.contractDetails.document.paragraphs.map((paragraph: Paragraph, para_index: any) => {
                                                                                    return <div className="paragraph" key={para_index}>
                                                                                        {paragraph.sentences.map((sentence: Sentence, sent_index: any) => {
                                                                                            let rules: Rule[] = ((this.state.tabRules.hasOwnProperty(sense.uri) && this.state.tabRules[sense.uri].hasOwnProperty(sentence.index)) ? this.state.tabRules[sense.uri][sentence.index] : []);
                                                                                            let annotated_text = sentence.text;
                                                                                            if (rules.length > 0) {
                                                                                                current_relevant_sentence_id++;
                                                                                                if ((current_relevant_sentence_id - 1) === this.state.selectedSentenceIndex) {
                                                                                                    rules.forEach(function (rule: Rule) {
                                                                                                        if (rule.conceptMatchings) {
                                                                                                            rule.conceptMatchings.forEach(function (concept: ConceptMatching) {
                                                                                                                let concept_text = sentence.text.substr(concept.beginIndex, (concept.endIndex - concept.beginIndex + 1));
                                                                                                                if (concept_text.length > 0) {
                                                                                                                    annotated_text = annotated_text.split(concept_text).join('<span class="conAct">' + concept_text + '</span>');
                                                                                                                }
                                                                                                            });
                                                                                                        }
                                                                                                    });
                                                                                                }
                                                                                            }

                                                                                            return <div key={para_index + '|' + sent_index}>
                                                                                                <Overlay
                                                                                                    target={rules.length > 0 ? this.state.tabReferences[sense.uri][current_relevant_sentence_id - 1] : null}
                                                                                                    show={rules.length > 0 && this.state.selectedSentenceIndex === (current_relevant_sentence_id - 1)}
                                                                                                    placement="left"
                                                                                                    container={this.state.tabReferences[sense.uri][current_relevant_sentence_id - 1]}>
                                                                                                        <Tooltip
                                                                                                            id={"sentence-tooltip-" + para_index + '|' + sent_index}
                                                                                                            placement="left"
                                                                                                            >
                                                                                                            {HtmlParser(this.state.tooltipText.length > 0 ? this.state.tooltipText : '')}
                                                                                                        </Tooltip>
                                                                                                </Overlay>
                                                                                                <div ref={rules.length > 0 ? this.state.tabReferences[sense.uri][current_relevant_sentence_id - 1] : null}
                                                                                                        className={"sentence sentence-level-" + (sentence.metadata ? sentence.metadata.level : 0) + ((sentence.metadata && sentence.metadata.level && sentence.metadata.level > 0 && sentence.metadata.level < 2) ? ' headline' : '') + (rules.length > 0 ? ' sense-relevant-sentence' : '') + (this.state.selectedSentenceIndex === (current_relevant_sentence_id - 1) ? ' active' : '')}
                                                                                                        {...((rules.length > 0) && {onClick: () => this.setSentenceId(Object.keys(this.state.tabRules[sense.uri]).indexOf('' + sentence.index))})}>
                                                                                                <div className="sentence-text" dangerouslySetInnerHTML={{__html: annotated_text}}/>
                                                                                            </div></div>
                                                                                        })}
                                                                                    </div>
                                                                                })}
                                                                            </div>
                                                                        </div>) : ''}
                                                                </Tab>
                                                            }
                                                            else return ''
                                                        }) : ''}
                                                    </Tabs>
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    ) : null
                                }) : <div className="row" ><div className="contract-list-empty col-12 col-sm-12 col-md-12 col-lg-12">There are no contracts matching your filters.</div></div>}
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        );
    }
}

// noinspection TypeScriptUnresolvedVariable
const mapStateToProps = (state:AppState) => ({
    contracts: state.contracts
});

export default connect(
    mapStateToProps,
    { getSenses, getContracts, getContractDetails, getConcepts}
)(ContractTool);
