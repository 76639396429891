import * as React from 'react';
// import { Link } from "react-router-dom";

interface AboutPageState {}
interface AboutPageProps {}

export default class AboutPage extends React.Component<AboutPageProps, AboutPageState> {
    render () {
        return (
            <div className="container">
                <div className="row startpage-intro">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-12">
                        <article className="article">
                            <div className="article-details">
                                <div className="article-title">
                                    <h2>Contract Intelligence With PoolParty Sense Extraction</h2>
                                </div>
                                <div className="article-content">
                                    <h4>Make contract management much easier</h4>
                                    <p>Extract information from current and legacy contracts. Quickly gain insights into your relationships with customers, vendors and partners. Minimize your organization’s risk, stay compliant, uncover hidden costs and opportunities and make informed business decisions.</p>
                                    <p>Organizations that cannot easily access the information that is their legal contracts risk:</p>

                                    <ol>
                                        <li>Non-compliance;</li>
                                        <li>Missing out on opportunities;</li>
                                        <li>Paying for products or services they no longer need.</li>
                                    </ol>

                                    <p>Manually reviewing all of a company’s contracts can be time-consuming and cost-prohibitive. Various industries put the cost of contract management inefficiencies between 5 and 40 percent of total contract value.</p>
                                        <h4>Know your contracts</h4>

                                    <p>Use PoolParty Sense Extraction to make your contracts searchable. Extract critical information from contracts and make it easy to execute compliance, accounting and contract review.</p>
                                    <h4>PoolParty’s Technical Advantages</h4>
                                    <p>PoolParty is the best and most versatile data enrichment and analysis software on the market. Contract Intelligence solutions built with PoolParty benefit from its many features (<a href="https://www.poolparty.biz/poolparty-extractor" target="blank">extractor</a>, <a href="https://www.poolparty.biz/semantic-classifier" target="blank">classifier</a> and <a href="https://www.poolparty.biz/agile-data-integration" target="blank">integrator</a>) and improve contract management by extracting information from contracts and linking them in intelligent ways. As a result, these applications will  perform at a much higher level than competitors by producing better results that can be understood and trusted by users.</p>
                                    <h4>Agile Data Integration</h4>
                                    <p>Contract Intelligence applications built with PoolParty can access data stored in various different systems and have a full overview of all their data in one single place, without the need to create and build more data.</p>
                                    <h4>The Knowledge Graph</h4>
                                    <p>PoolParty is a fantastic tool to build and manage a knowledge graph, which is a representation of knowledge. The knowledge graph contains terms and concepts in a given field of knowledge and puts every piece of data into a larger frame, revealing its connections and meaning in a specific context. It connects information by enriching data with machine-readable meaning by creating a context for an object. Moreover, a knowledge graph dramatically increases the quality of the results delivered by machine learning algorithms used in contract intelligence. It also makes them much easier to amend, since concepts changed in the graph directly alter the algorithms.</p>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        );
    }
}
