import {
    GET_CONCEPTS,
    SHOW_CONTRACT_DETAILS,
    SHOW_CONTRACTS,
    UPDATE_SENSES
} from "./types";
import {
    ContractsItem, GetContractApi,
    GetContractsApi,
    GetSensesApi,
    SenseItem, Contract, GetConceptsApi
} from "./api";

// Get the senses filters.
export const getSenses = () => (dispatch:any) => {
    // Getting the senses.
    let api = new GetSensesApi();
    api.getSenses().then(res => {
        let items: Array<SenseItem> = ((res.sensesItems) ? res.sensesItems : []);
        // Sort the sense list alphabetically.
        items.sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));
        dispatch(updateSenses(items));
    })
    .catch(err =>
        console.log('update senses error')
    );
};

// Get the senses filters.
export const getContracts = () => (dispatch:any) => {
    // Getting the senses.
    let api = new GetContractsApi();
    api.getContracts().then(res => {
        let items: Array<ContractsItem> = [];
        let contract_ids: string[] = [];
        // Make sure there aren't any duplicate documents.
        if (res.contracts) {
            for (let contract_index = 0; contract_index < res.contracts.length; contract_index++) {
                let current_contract = res.contracts[contract_index];
                if (contract_ids.indexOf(current_contract.contract) === -1) {
                    contract_ids.push(current_contract.contract);
                    items.push(current_contract);
                }
            }
        }
        dispatch(showContracts(items));
    })
        .catch(err =>
            console.log('get contracts error')
        );
};

// Get the senses filters.
export const getContractDetails = (contractId: string) => (dispatch:any) => {
    // Getting the senses.
    let api = new GetContractApi();
    api.getContract(contractId).then(res => {
        // Sort the sense items alphabetically.
        if (res.senses.length > 0) {
            res.senses[0].senses.sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
        }
        dispatch(showContractDetails(res));
    })
        .catch(err => {
            console.log('get contract details error')}
        );
};

// Get the concepts.
export const getConcepts = () => (dispatch:any) => {
    let api = new GetConceptsApi();
    api.getConcepts().then(res => {
        let items:any = {};
        // Build an associative array by URI.
        if (res.conceptsItems) {
            res.conceptsItems.forEach(function (concept) {
                items[concept.uri] = concept;
            });
        }

        dispatch(updateConcepts(items));
    })
        .catch(err =>
            console.log('get concepts error')
        );
};

// Set logged in user
export const showContracts = (results:Array<ContractsItem>) => {
    return {
        type: SHOW_CONTRACTS,
        payload: results
    };
};

// Show the contract details
export const showContractDetails = (contract:Contract) => {
    return {
        type: SHOW_CONTRACT_DETAILS,
        payload: contract
    };
};

export const updateSenses = (results:Array<SenseItem>) => {
    return {
        type: UPDATE_SENSES,
        payload: results
    };
};

export const updateConcepts = (concepts:any) => {
    return {
        type: GET_CONCEPTS,
        payload: concepts
    };
};
