import {ContractsItem, SenseItem, Contract} from "./api";

export const SHOW_CONTRACTS = "SHOW_CONTRACTS";
export const SHOW_CONTRACT_DETAILS = "SHOW_CONTRACT_DETAILS";
export const UPDATE_SENSES = "UPDATE_SENSES";
export const GET_CONCEPTS = "GET_CONCEPTS";

export interface ContractsState {
    senses: Array<SenseItem>,
    contracts: Array<ContractsItem>,
    contractDetails: Contract,
    concepts: any
}

export interface ContractsResults {
    success: boolean,
    contracts: any,
    message: string
}

export interface ContractDetailsResults {
    success: boolean,
    details: any,
    message: string
}

export interface SensesResults {
    success: boolean,
    senses: any,
    message: string
}

export interface ConceptsResults {
    success: boolean,
    concepts: any,
    message: string
}

interface showContracts {
    type: typeof SHOW_CONTRACTS
    payload: Array<ContractsItem>
}

interface showContractDetails {
    type: typeof SHOW_CONTRACT_DETAILS
    payload: Contract
}

interface showSenses {
    type: typeof UPDATE_SENSES
    payload: Array<SenseItem>
}

interface showConcepts {
    type: typeof GET_CONCEPTS
    payload: any
}

export type ContractsActions = showContracts | showContractDetails | showSenses | showConcepts;
