import React from 'react';
import { HashRouter as Router, Route } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./store/store";
import './styles/css/App.css';
import Navbar from "./components/layout/Navbar";
import ContractTool from "./components/contract_tool/ContractTool";
import AboutPage from "./components/static/About";

const App: React.FC = () => {
  return (
      <Provider store={store}>
          <Router>
            <div id="app" className="App">
                <div className="main-wrapper">
                    <Navbar />
                    <div className="main-content">
                        <section className="section">
                            <div className="section-body">
                                <Route exact path="/" component={ContractTool} />
                                <Route exact path="/recommender" component={ContractTool} />
                                <Route exact path="/about" render={(props) => <AboutPage {...props} /> } />
                            </div>
                        </section>
                    </div>
                </div>
            </div>
          </Router>
      </Provider>
  );
};

export default App;
