/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Contract intelligence API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "";

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    // @ts-ignore
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    // @ts-ignore
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 *
 * @export
 * @interface Area
 */
export interface Area {
    /**
     *
     * @type {Array<Rule>}
     * @memberof Area
     */
    rules?: Array<Rule>;
    /**
     *
     * @type {Array<AreaSentences>}
     * @memberof Area
     */
    sentences: Array<AreaSentences>;
}

/**
 *
 * @export
 * @interface AreaSentences
 */
export interface AreaSentences {
    /**
     *
     * @type {number}
     * @memberof AreaSentences
     */
    index: number;
}

/**
 *
 * @export
 * @interface Concept
 */
export interface Concept {
    /**
     *
     * @type {string}
     * @memberof Concept
     */
    prefLabel: string;
    /**
     *
     * @type {Array<ScopeNote>}
     * @memberof Concept
     */
    scopeNotes?: Array<ScopeNote>;
    /**
     *
     * @type {string}
     * @memberof Concept
     */
    uri: string;
}

/**
 *
 * @export
 * @interface ConceptMatching
 */
export interface ConceptMatching {
    /**
     *
     * @type {number}
     * @memberof ConceptMatching
     */
    beginIndex: number;
    /**
     *
     * @type {number}
     * @memberof ConceptMatching
     */
    endIndex: number;
    /**
     *
     * @type {number}
     * @memberof ConceptMatching
     */
    index: number;
    /**
     *
     * @type {string}
     * @memberof ConceptMatching
     */
    uri: string;
}

/**
 *
 * @export
 * @interface ConceptsList
 */
export interface ConceptsList {
    /**
     *
     * @type {Array<Concept>}
     * @memberof ConceptsList
     */
    conceptsItems?: Array<Concept>;
}

/**
 *
 * @export
 * @interface Contract
 */
export interface Contract {
    /**
     *
     * @type {Document}
     * @memberof Contract
     */
    document: Document;
    /**
     *
     * @type {Array<Senses>}
     * @memberof Contract
     */
    senses: Array<Senses>;
}

/**
 *
 * @export
 * @interface ContractsItem
 */
export interface ContractsItem {
    /**
     *
     * @type {string}
     * @memberof ContractsItem
     */
    title: string;

    /**
     *
     * @type {string}
     * @memberof ContractsItem
     */
    contract: string;

    /**
     *
     * @type {string}
     * @memberof ContractsItem
     */
    url: string;

    /**
     *
     * @type {Array<SenseItem>}
     * @memberof ContractsItem
     */
    senseItems: Array<SenseItem>;
}

/**
 *
 * @export
 * @interface ContractsList
 */
export interface ContractsList {
    /**
     *
     * @type {Array<ContractsItem>}
     * @memberof ContractsList
     */
    contracts?: Array<ContractsItem>;
}

/**
 *
 * @export
 * @interface Document
 */
export interface Document {
    /**
     *
     * @type {Metadata}
     * @memberof Document
     */
    metadata?: Metadata;
    /**
     *
     * @type {Array<Paragraph>}
     * @memberof Document
     */
    paragraphs: Array<Paragraph>;
    /**
     *
     * @type {string}
     * @memberof Document
     */
    text: string;
    /**
     *
     * @type {string}
     * @memberof Document
     */
    title: string;
    /**
     *
     * @type {string}
     * @memberof Document
     */
    uri: string;
}

/**
 *
 * @export
 * @interface Metadata
 */
export interface Metadata {
    /**
     *
     * @type {number}
     * @memberof Metadata
     */
    level?: number;
}

/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     *
     * @type {number}
     * @memberof ModelError
     */
    code: number;
    /**
     *
     * @type {string}
     * @memberof ModelError
     */
    message: string;
}

/**
 *
 * @export
 * @interface Paragraph
 */
export interface Paragraph {
    /**
     *
     * @type {number}
     * @memberof Paragraph
     */
    index: number;
    /**
     *
     * @type {Metadata}
     * @memberof Paragraph
     */
    metadata?: Metadata;
    /**
     *
     * @type {Array<Sentence>}
     * @memberof Paragraph
     */
    sentences: Array<Sentence>;
    /**
     *
     * @type {string}
     * @memberof Paragraph
     */
    uri: string;
}

/**
 *
 * @export
 * @interface Rule
 */
export interface Rule {
    /**
     *
     * @type {Array<ConceptMatching>}
     * @memberof Rule
     */
    conceptMatchings?: Array<ConceptMatching>;
    /**
     *
     * @type {string}
     * @memberof Rule
     */
    title: string;
    /**
     *
     * @type {string}
     * @memberof Rule
     */
    uri: string;
}

/**
 *
 * @export
 * @interface ScopeNote
 */
export interface ScopeNote {
    /**
     *
     * @type {string}
     * @memberof ScopeNote
     */
    language?: string;
    /**
     *
     * @type {string}
     * @memberof ScopeNote
     */
    text?: string;
}

/**
 *
 * @export
 * @interface Sense
 */
export interface Sense {
    /**
     *
     * @type {Array<Area>}
     * @memberof Sense
     */
    areas: Array<Area>;
    /**
     *
     * @type {string}
     * @memberof Sense
     */
    title: string;
    /**
     *
     * @type {string}
     * @memberof Sense
     */
    uri: string;
}

/**
 *
 * @export
 * @interface SenseItem
 */
export interface SenseItem {
    /**
     *
     * @type {string}
     * @memberof SenseItem
     */
    title: string;
    /**
     *
     * @type {string}
     * @memberof SenseItem
     */
    uri: string;
}

/**
 *
 * @export
 * @interface Senses
 */
export interface Senses {
    /**
     *
     * @type {Array<Sense>}
     * @memberof Senses
     */
    senses: Array<Sense>;
    /**
     *
     * @type {string}
     * @memberof Senses
     */
    title: string;
    /**
     *
     * @type {string}
     * @memberof Senses
     */
    uri: string;
}

/**
 *
 * @export
 * @interface SensesList
 */
export interface SensesList {
    /**
     *
     * @type {Array<SenseItem>}
     * @memberof SensesList
     */
    sensesItems?: Array<SenseItem>;
}

/**
 *
 * @export
 * @interface Sentence
 */
export interface Sentence {
    /**
     *
     * @type {number}
     * @memberof Sentence
     */
    index: number;
    /**
     *
     * @type {Metadata}
     * @memberof Sentence
     */
    metadata?: Metadata;
    /**
     *
     * @type {string}
     * @memberof Sentence
     */
    text: string;
    /**
     *
     * @type {string}
     * @memberof Sentence
     */
    uri: string;
}

/**
 * GetConceptsApi - fetch parameter creator
 * @export
 */
export const GetConceptsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get list of concepts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConcepts(options: any = {}): FetchArgs {
            const localVarPath = `/api/media/text/getConcepts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetConceptsApi - functional programming interface
 * @export
 */
export const GetConceptsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get list of concepts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConcepts(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConceptsList> {
            const localVarFetchArgs = GetConceptsApiFetchParamCreator(configuration).getConcepts(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GetConceptsApi - factory interface
 * @export
 */
export const GetConceptsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary Get list of concepts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConcepts(options?: any) {
            return GetConceptsApiFp(configuration).getConcepts(options)(fetch, basePath);
        },
    };
};

/**
 * GetConceptsApi - object-oriented interface
 * @export
 * @class GetConceptsApi
 * @extends {BaseAPI}
 */
export class GetConceptsApi extends BaseAPI {
    /**
     *
     * @summary Get list of concepts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetConceptsApi
     */
    public getConcepts(options?: any) {
        return GetConceptsApiFp(this.configuration).getConcepts(options)(this.fetch, this.basePath);
    }

}

/**
 * GetContractApi - fetch parameter creator
 * @export
 */
export const GetContractApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get document
         * @param {string} [uri] uri
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContract(uri?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/media/text/getDocument`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {
                uri: uri
            } as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            // const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            // localVarRequestOptions.body =  needsSerialization ? JSON.stringify(uri || {}) : (uri || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetContractApi - functional programming interface
 * @export
 */
export const GetContractApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get document
         * @param {string} [uri] uri
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContract(uri?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Contract> {
            const localVarFetchArgs = GetContractApiFetchParamCreator(configuration).getContract(uri, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GetContractApi - factory interface
 * @export
 */
export const GetContractApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary Get document
         * @param {string} [uri] uri
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContract(uri?: string, options?: any) {
            return GetContractApiFp(configuration).getContract(uri, options)(fetch, basePath);
        },
    };
};

/**
 * GetContractApi - object-oriented interface
 * @export
 * @class GetContractApi
 * @extends {BaseAPI}
 */
export class GetContractApi extends BaseAPI {
    /**
     *
     * @summary Get document
     * @param {string} [uri] uri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetContractApi
     */
    public getContract(uri?: string, options?: any) {
        return GetContractApiFp(this.configuration).getContract(uri, options)(this.fetch, this.basePath);
    }

}

/**
 * GetContractsApi - fetch parameter creator
 * @export
 */
export const GetContractsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get list of documents annotated with senses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContracts(options: any = {}): FetchArgs {
            const localVarPath = `/api/media/text/getDocuments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetContractsApi - functional programming interface
 * @export
 */
export const GetContractsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get list of documents annotated with senses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContracts(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ContractsList> {
            const localVarFetchArgs = GetContractsApiFetchParamCreator(configuration).getContracts(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GetContractsApi - factory interface
 * @export
 */
export const GetContractsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary Get list of documents annotated with senses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContracts(options?: any) {
            return GetContractsApiFp(configuration).getContracts(options)(fetch, basePath);
        },
    };
};

/**
 * GetContractsApi - object-oriented interface
 * @export
 * @class GetContractsApi
 * @extends {BaseAPI}
 */
export class GetContractsApi extends BaseAPI {
    /**
     *
     * @summary Get list of documents annotated with senses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetContractsApi
     */
    public getContracts(options?: any) {
        return GetContractsApiFp(this.configuration).getContracts(options)(this.fetch, this.basePath);
    }

}

/**
 * GetSensesApi - fetch parameter creator
 * @export
 */
export const GetSensesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get list of senses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSenses(options: any = {}): FetchArgs {
            const localVarPath = `/api/media/text/getSenses`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetSensesApi - functional programming interface
 * @export
 */
export const GetSensesApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get list of senses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSenses(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SensesList> {
            const localVarFetchArgs = GetSensesApiFetchParamCreator(configuration).getSenses(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GetSensesApi - factory interface
 * @export
 */
export const GetSensesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary Get list of senses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSenses(options?: any) {
            return GetSensesApiFp(configuration).getSenses(options)(fetch, basePath);
        },
    };
};

/**
 * GetSensesApi - object-oriented interface
 * @export
 * @class GetSensesApi
 * @extends {BaseAPI}
 */
export class GetSensesApi extends BaseAPI {
    /**
     *
     * @summary Get list of senses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetSensesApi
     */
    public getSenses(options?: any) {
        return GetSensesApiFp(this.configuration).getSenses(options)(this.fetch, this.basePath);
    }

}

